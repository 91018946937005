
import RegisterViewModelUtils from "./RegisterViewModelUtils.js";

import StringUtils from '@/utils/StringUtils.js';
import WebLogger from "@/domain/logger/WebLogger.js";
import ConstUtils from '@/utils/ConstUtils.js';

import EventUtils from '@/utils/EventUtils.js';

import UserEvents from "@/domain/model/user/UserEvents.js";
import EventsDomain from '@/domain/model/events/EventsDomain.js';
import { uuid } from 'vue-uuid'

export default class RegisterViewModel {
  
  static Fields = {
      SendButton: "send",
      BackButton: "back",
  }
  
  static Values = {
      States: {
        Ready: "ready",
        Sending: "sending",
        Done: "done",
      }
  }
  
  constructor(panel, registration) {
    this._panel = panel;
    this._state = RegisterViewModel.Values.States.Ready;
    this._registration = registration;
    this._tableData = [];
    this._domain = this.panel().domain;
    this._logger = new WebLogger(window.console, true);
    this._showMissingRequirements = false;
    this._error = {
      messageId: "",
      hint: ""
    };
    this._timer = null;
    this._status = "";
    this._checklistDataLoaded = false;
    this._scheduleDataLoaded = false;
    this.logger().info("Start: {0}", [JSON.stringify(this._registration)]);
    this._showCompanyRoleOther = false;
    this._showSocialOther = false;
  }
  
  start() {
    this.populateData();
  }
  
  populateData() {
    this._tableData = [];
    var newData = []
    this.buildRowData(newData)
    this._tableData = newData;
    return this;
  }
  
  buildRowData(newData) {
    var utils = new RegisterViewModelUtils(this, newData);
    utils.start();
  }
  
  domain() {
    return this._domain;
  }
  
  panel() {
    return this._panel;
  }
  
  logger() {
    return this._logger;
  }
  
  registration() {
    return this._registration;
  }
  
  showCompanyRoleOther() {
    return this._showCompanyRoleOther;
  }
  
  showSocialOther() {
    return this._showSocialOther;
  }
  
  withValue(kvp) {
    this.logger().info("Received: {0}", [JSON.stringify(kvp)]);
    this._showMissingRequirements = false;
    if (!kvp) {
      return;
    }

    var field = kvp['field'];
    var fieldValue = kvp['value'];
    var objectId = kvp['id'];
    if (!objectId) {
      return;
    }
    
    this._registration.put(field, fieldValue);
    
    if (field === "companyRole") { 
      var otherRole = false;
      if (fieldValue === "other") {
        otherRole = true;
      }
      if (otherRole != this._showCompanyRoleOther) {
        this._showCompanyRoleOther = otherRole;
        this.panel().reloadData();
      }
    }
    if (field == "ads") {
      var otherSocial = false;
      if (fieldValue === "Other") {
        otherSocial = true;
      }
      if (fieldValue === "Friend") {
        otherSocial = true;
      }
      if (otherSocial != this._showSocialOther) {
        this._showSocialOther = otherSocial;
        this.panel().reloadData();
      }
    }
  }
  
  /**
   * Handle buttons being pressed.
   */
  withButtonPressed(buttonData) {
    this.logger().info("Button: {0}", [JSON.stringify(buttonData)]);
    if (!buttonData) {
      return;
    }
    var field = buttonData['field'];
    if (!field) {
      return;
    }
    if (StringUtils.e(RegisterViewModel.Fields.SendButton, field)) {
      if (!this.areRequirementsMet()) {
        this._showMissingRequirements = true;
        return;
      }
      
      this._state = RegisterViewModel.Values.States.Sending;
      this.withError({
        messageId: "",
        hint: ""
      });
      this.logger().info("Received: {0}", [JSON.stringify(this.registration())]);
      this.panel().populateData();
      this.panel().sendDelayed();
    }
    if (StringUtils.e(RegisterViewModel.Fields.BackButton, field)) {
      this.panel().$router.replace(
        { name: this.panel().$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN }
        );
    }
  }
  
  withStatus(status) {
    this._status = status;
  }

  status() {
    return this._status;
  }

  sendIfPossible() {
    this._state = RegisterViewModel.Values.States.Sending;
    if (this.panel().auth_connected) {
      this.send();
    } else {
      this.panel().authClear();
      try {
        this.panel().$connect();
      } catch (e) {
        this.error = {
            messageId: "NoConnection",
            hint: "It appears that ReadyChek.cloud is not reachable at the moment"
        }
      }
    }
  }

  send(token) {
    var error =  {
      messageId: "",
      hint: ""
    }
    this.withError(error);
    this._state = RegisterViewModel.Values.States.Sending;

    const requestEvent = UserEvents.SignUp.Request(this.registration().data);
    if (token) {
      requestEvent[ConstUtils.GRECAPTCHA.NAME] = token;
    }
    requestEvent['Saas'] = "Software_Agreement_v1.2.pdf";
    
    EventsDomain.initEvent(requestEvent, null, uuid.v1(), 
      this.panel().auth_client.deviceId, 
      this.panel().auth_client.version);

    EventUtils.Send.event(this.panel().$socket, requestEvent);

    this._state = RegisterViewModel.Values.States.Done;
  }

  showMissingRequirements() {
    return this._showMissingRequirements;
  }

  tableData() {
    return this._tableData;
  }
  
  isStateReady() {
    return StringUtils.e(this._state, RegisterViewModel.Values.States.Ready);
  }
  
  isStateSending() {
    return StringUtils.e(this._state, RegisterViewModel.Values.States.Sending);
  }

  isStateDone() {
    return StringUtils.e(this._state, RegisterViewModel.Values.States.Done);
  }

  withError(error) {
    this._error = error;
    this._state = RegisterViewModel.Values.States.Ready;
    return this;
  }

  withErrorUnableToConnectToApi() {
    var error =  {
      messageId: "NoConnectionToApi",
      hint: "It appears that ReadyChek.cloud's API is not reachable, please try again"
    }
    this.withError(error);
    return this;
  }

  error() {
    return this._error;
  }
  
  areRequirementsMet() {
    var met = this.areRequirementsMetInChildren(this._tableData);
    return met;
  }
  
  areRequirementsMetInChildren(kids) {
    var met = true;
    for (var i = 0; i < kids.length; i++) {
      var row = kids[i];
      if (row.mandatoryValue()) {
        var field = row.field();
        var value = this.registration().get(field);
        this.logger().info("{0}={1}", [field, value])
        if (StringUtils.isEmpty(value)) {
          met = false;
          break;
        }
      }
      if (row.children().length > 0) {
        met = this.areRequirementsMetInChildren(row.children());
        if (!met) {
          break;
        }
      }
    }
    return met;
  }
}







