<template>

  <Row>
    <Column :width="15">
      <Button :go="backgroundType" :type="buttonType" v-on:click="pressed"> 
      {{ ContentUtils.decodeText(row.name()) }} 
      </Button>
    </Column>
  </Row>
  
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import ContentUtils from '@/utils/ContentUtils.js';

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import ButtonDisplayRow from "./ButtonDisplayRow.js";

export default {
  name: 'button-display-cell',
  components: {
    Row, Column, Button
  },
  props: {
    css: { type: String, default: "" },
    data: null,
  },
  data() {
    return {
      row : new ButtonDisplayRow(),
      MC : new MC(),
      ContentUtils: ContentUtils,
      ButtonDisplayRow: ButtonDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    backgroundType: function() {
      return this.row.isBackgroundPrimaryAction();
    },
    buttonType: function() {
      return this.row.isBackgroundSecondaryAction() ? "secondaryAction" : "";
    }
  },
  mounted: function () {
    this.row.data = this.data;
  },
  methods: {
    pressed: function() {
      var data = {};
      data['id'] = this.row.id();
      data['object'] = this.row.object();
      data['field'] = this.row.field();
      data['key'] = this.row.key();
      data['value'] = this.row.defaultValue();
      this.$emit('click', data);
    },
  },
}
</script>