
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class CountryUpdateRow extends RcTableViewRow {

  static Id = "CountryUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, CountryUpdateRow.Id);
  }

}