<template>

  <Row v-if="ready">
    <Column :width="15">
      <center>
        <img width="25%" src="@/assets/2024/RemarksIcon.png" />
      </center>
    </Column>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";
import RemarksImageDisplayRow from "./RemarksImageDisplayRow.js";
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
export default {
  name: 'remarks-image-display-cell',
  components: {
    Row,Column,
  },
  props: {
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new RemarksImageDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>