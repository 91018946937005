
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import StringUtils  from '@/utils/StringUtils.js';

export default class RowDisplayRow extends RcTableViewRow {

  static Id = "RowDisplayRow";
  static Border = "border";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, RowDisplayRow.Id);
    this.withBorder(false);
    this.withChildren([]);
  }

  withBackgroundTitle() {
    this.put("background", "title")
    return this;
  }
  isBackgroundTitle() {
    return StringUtils.eic(this.getString("background"), "title");
  }

  withBorder(border) {
    this.put(RowDisplayRow.Border, border);
    return this;
  }
  
  border() {
    return this.get(RowDisplayRow.Border);
  }
  
}