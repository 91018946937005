<template>

  <KeyList v-if="ready" :class="css"
    :componentName="componentName"
    :showLabel="row.showLabel()"
    :title="row.field()" 
    :placeholder="row.name()" 
    :required="showMissingRequirements"
    :defaultValue="row.defaultValue()"
    :options="row.options()"
    :mandatory="row.mandatoryValue()"
    :hint="row.hint()"
    :hintUrl="row.hintUrl()"
    v-on:value="withValue" 
    />
  
</template>

<script>

import { mapGetters } from 'vuex'

import StringListUpdateRow from "./StringListUpdateRow.js";

import KeyList from '@/components/input/KeyList.vue';

export default {
  name: 'string-update-cell',
  components: {
    KeyList,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      componentName: "",
      rowData: new StringListUpdateRow(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.componentName = this.rowData.field();
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        kvp['field'] = this.row.field();
        kvp['key'] = this.row.key();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>