
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class RemarksImageDisplayRow extends RcTableViewRow {

  static Id = "RemarksImageDisplayRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, RemarksImageDisplayRow.Id);
  }
  
}