<template>

    <Row v-if="ready">
     <Column :width="15">
       <Box>
         <PictureDisplayCell :placeholderKey="placeholderKey" :data="defaultValue"/>
         <Row>
           <Column>
             <Box>
               <ErrorRow :error="status" />
             </Box>
           </Column>
         </Row>
         <Row>
           <Column :width="3" class="w3-center" />
           <Column :width="9" class="w3-center">
             <Button v-on:click="$refs.getFile.click()">Upload a Picture</Button>
             <input type="file" id="getFile" ref="getFile" v-on:change="handleFileUpload" style="display:none"/>
           </Column>
           <Column :width="3" class="w3-center" />
         </Row>
         
       </Box>
       
     </Column>
    </Row>

</template>

<script>

import heic2any from "heic2any";

import { mapGetters } from 'vuex'

//import ConstUtils from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import ImageImporter from '@/utils/ImageUtils.js';
import ErrorRow from '@/components/row/ErrorRow.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import Button from "@/portals/shared/library/button/Button.vue";

import PictureDisplayCell from "./PictureDisplayCell.vue";
import PictureUpdateRow from "./PictureUpdateRow.js";
import ClientException from '@/domain/model/client/ClientException.js';

export default {
  name: 'picture-update-cell',
  components: {
    PictureDisplayCell,
    Box, Button,
    Row, Column,
    ErrorRow,
  },
  
  props: {
    align:    { type: String, default: "left" },
    cmpt:     { type: String, default: "title" },
    fontSize: { type: String, default: "medium" },
    subtitle: { type: Boolean, default: false },
    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },
    type:     { type: String, default: "" },
    data:     { type: Object, default: () => {} },
    placeholderKeyDefault: { type: String, default: "truck" },
  },
  
  data() {
    return {
      imageData: null,
      defaultValue: {},
      rowData: new PictureUpdateRow(),
      ready: false,
      status: null, 
    }
  },
  
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
    placeholderKey: function() {
      if (this.imageData) {
        return "none";
      }
      return this.placeholderKeyDefault;
    }
  },
  
  mounted: function () {
    this.rowData.data = this.data;
    this.defaultValue = this.rowData.defaultValue();
    this.ready = true;
  },
  
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.rowData.id();
        kvp['object'] = this.rowData.object();
        kvp['field'] = this.rowData.field();
        kvp['key'] = this.row.key();
        this.$emit('withValue', kvp);
      }
    },
    classes: function() {
      var value = "";
      if (this.fontSize) {
        value = "rc-text-" + this.fontSize;
      }

      if (this.subtitle) {
        value = "rc-text-small";
      }
      
      if (this.align === "left") {
        value += " rc-text-left";
      } else if (this.align === "right") {
        value += " rc-text-right";
      } else {
        value += " rc-text-centre";
      }
      return this.background() + " " + value;
    },
    
    background: function() {
      var value = "rc-title-backgroundV2";
      if (this.subtitle) {
        value = "rc-title-background-light";  
      }
      if (this.rowData.savingTitle()) {
        value = "rc-background-good";
        return value;
      }
      if (this.type === "warning") {
        value = " rc-title-warning-left";
      }
      if (this.type === "error") {
        value = " rc-title-error-left";
      }
      return value;
    },
    
    handleFileUpload: function(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      var file = files[0];
      this.status = null;
      
      if (!StringUtils.contains(file.type, 'image')) {
        this.domain.logger().info("Not an image");
        this.status =  {
         messageId: "NotAnImageFile",   
         hintId: "Please choose a valid image."
        }
        return;
      }

      var vm = this;
      var fileName = file.name.toLowerCase();
      if (StringUtils.contains(fileName, ".heic")) {
        var params = {
            blob: file,
            toType: "image/png",
          };
        heic2any(params)
          .then(function (resultBlob) {
            var properties = { type: "image/png", lastModified: new Date().getTime() };
            let newFile = new File([resultBlob], fileName + ".png", properties) ;
            vm.readImage(newFile)
          })
          .catch(function (x) {
            vm.status = {
                messageId: "ProblemWithFile",
                hintId: "There was a problem converting please choose a different file."
            }
            vm.domain.logger().info(x);
            var ce = new ClientException(vm.domain);
            ce
              .withStackTrace(x)
              .withMaker(vm.navigator.appVersion)
              .withDevice(vm.domain.client().deviceId)
              .withVersion(vm.domain.client().version)
              .with(vm.domain.session().employee())
              .with(vm.domain.session().company())
              .with(vm.domain.session().user())
              .withStateSaved()
              .withClient()
              .done();
            var exception = vm.domain.events().client().exception(ce);
            exception.send();
         });
      } else {
        this.readImage(file);
      }
    },
    
    readImage: function(file) {
      var vm = this;
      ImageImporter
        .resizeImage({ file: file, maxSize: 300 })
        .then((resizedImage) => {
          var reader = new FileReader();
          reader.onloadend = function() {
            var base64data = reader.result;
            vm.imageData = base64data;
            vm.withValue({
              key: "picture",
              value: vm.imageData,
            });
            vm.defaultValue = {
                imageData: base64data,
            }
          }
          reader.readAsDataURL(resizedImage);
          vm.domain.logger().info("done with image, read successful");
        })
        .catch((x) => {
          vm.status = {
              messageId: "ProblemWithFile",
              hint: "There was a problem converting please choose a different file."
          }
          vm.domain.logger().info(x);
          var ce = new ClientException(vm.domain);
          ce
            .withStackTrace(x)
            .withMaker(vm.navigator.appVersion)
            .withDevice(vm.domain.client().deviceId)
            .withVersion(vm.domain.client().version)
            .with(vm.domain.session().employee())
            .with(vm.domain.session().company())
            .with(vm.domain.session().user())
            .withStateSaved()
            .withClient()
            .done();
          var exception = vm.domain.events().client().exception(ce);
          exception.send();
          
        });
    },
  },
}
</script>