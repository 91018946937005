
import RcObject from "@/domain/common/RcObject.js";

export default class RcTableViewRow extends RcObject {

  static Value = "value";
  static TypeId = "typeId";
  static Name = "name";
  static Hint = "hint";
  static Object = "object";
  static Field = "field";
  static Kids = "children";
  static Key = "key"
  static Mandatory = "mandatory";
  static width = "width";
  static even = "even";
  static PrimaryBackgroundColour = "primaryBackgroundColour";
  static BorderStyle = "borderStyle";
  static Selected = "selected";
  static ShowLabel = "showLabel";

  constructor() {
    super();
    this.withMandatoryValue(false);
    this.withEven(false);
    this.withChildren([]);
    this.withShowLabel(true);
  }

  withShowLabel(show) {
    this.put(RcTableViewRow.ShowLabel, show);
    return this;
  }

  showLabel() {
    return this.getBoolean(RcTableViewRow.ShowLabel);
  }

  withSelected(selected) {
    this.put(RcTableViewRow.Selected, selected);
    return this;
  }

  isSelected() {
    return this.getBoolean(RcTableViewRow.Selected);
  }


  withKey(key) {
    this.put(RcTableViewRow.Key, key);
    return this;
  }

  key() {
    return this.getString(RcTableViewRow.Key)
  }

  withField(field) {
    this.put("field", field);
    return this;
  }
  
  field() {
    return this.getString("field");
  }

  withPlaceholder(placeholder) {
    this.put("placeholder", placeholder);
    return this;
  }
  
  placeholder() {
    return this.getString("placeholder");
  }

  withDefaultValue(defaultValue) {
    this.put("defaultValue", defaultValue);
    return this;
  }
  
  defaultValue() {
    return this.get("defaultValue");
  }
  
  withMandatoryValue(mandatory) {
    this.put("mandatory", mandatory);
    return this;
  }
  
  mandatoryValue() {
    return this.getBoolean("mandatory");
  }
  
  withObject(object) {
    this.put("object", object);
    return this;
  }
  
  object() {
    return this.get("object");
  }
  
  withWidth(width) {
    this.put("width", width);
    return this;
  }
  
  width() {
    return this.getInt("width");
  }
  
  withId(id) {
    super.withId(id);
    return this;
  }
  
  withName(name) {
    this.put("name", name);
    return this;
  }
  
  name() {
    return this.getString("name");
  }
  
  withValue(value) {
    this.put("value", value);
    return this;
  }
  
  value() {
    return this.getString("value");
  }

  withHint(hint) {
    this.put("hint", hint);
    return this;
  }

  withHintUrl(hint) {
    this.put("hintUrl", hint);
    return this;
  }

  hintUrl() {
    return this.getString("hintUrl");
  }
  
  hint() {
    return this.getString("hint");
  }
  
  withEven(even) {
    this.put("even", even);
    return this;
  }
  
  even() {
    return this.getBoolean("even");
  }
  
  withChildren(kids) {
    this.put(RcTableViewRow.Kids, kids);
    return this;
  }

  withChild(kid) {
    var kids = this.children();
    kids.push(kid);
    this.withChildren(kids);
    return this;
  }

  children() {
    return this.get(RcTableViewRow.Kids);
  }

  hasChildren() {
    return this.children().length > 0;
  }
  
  withPrimaryBackgroundColour() {
    this.put(RcTableViewRow.PrimaryBackgroundColour, "primary-component-background-colour");
    return this;
  }

  backgroundColour() {
    if (this.even()) {
      return "rowEven"
    }
    return this.getString(RcTableViewRow.PrimaryBackgroundColour);
  }

  withBorderStyleLarge() {
    this.put(RcTableViewRow.BorderStyle, "large");
    return this;
  }

  borderStyle() {
    if (this.even()) {
      var borderStyle = this.getString(RcTableViewRow.BorderStyle);
      if (borderStyle) {
        return borderStyle + "-even";
      }
      return borderStyle;
    }
    return this.getString(RcTableViewRow.BorderStyle);
  }
}
