
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class EmailImageDisplayRow extends RcTableViewRow {

  static Id = "EmailImageDisplayRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, EmailImageDisplayRow.Id);
  }
  
}