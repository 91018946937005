<template>

  <LabelValueRow>
    <template #label>Status</template>
    <template #value>
      <Box>
        <Row>
          <div class="rc-box-col-4 rc-input-good rc-text-centre" 
            v-if="row.value() === 'None'">
            Good
          </div>
          <div class="rc-box-col-4 rc-input-minor rc-text-centre" 
            v-else-if="row.value() === 'Minor'">
            Minor
          </div>
          <div class="rc-box-col-4 rc-input-major rc-text-centre" 
            v-else-if="row.value() === 'Major'">
            Major
          </div>
          <div class="rc-box-col-5" />
        </Row>
      </Box>
    </template>
  </LabelValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';

import StatusDisplayRow from "./StatusDisplayRow.js";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

import MC from "@/domain/session/MC.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
//import Column from "@/portals/shared/library/box/Column.vue";
//import Inspection from '@/domain/model/inspection/Inspection.js';

export default {
  name: 'status-display-cell',
  components: {
    LabelValueRow,
    Box,Row, 
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: {},
  },
  data() {
    return {
      ready: false,
      rowData: new StatusDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>